import React, { Fragment, useState, useEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { Navbar, Nav } from "react-bootstrap"
import styles from "../scss/components/_Header.module.scss"

const Header = props => {
  console.log("esto son los props", props)

  const [background, setBackground] = useState("rgba(0,0,0,.8)")

  useEffect(() => {
    activeKey(window.location.pathname)
  }, [])

  const activeKey = location => {
    console.log("location", location)
    if (
      location !== "/" &&
      location !== "/budsummer/" &&
      location !== "/budx/"
    ) {
      document.getElementById(location).children[0].style.color = "#e31832"
    }
    if (location === "/budsummer/") {
      document.getElementById("black1").style.color = "black"
      document.getElementById("black2").style.color = "black"
      document.getElementById("black3").style.color = "black"
      document.getElementById("black4").style.color = "black"
      document.getElementById("black5").style.color = "black"
      document.getElementById("black5").style.border = "1px solid black"
    }
  }

  const changeBackground = () => {
    if (background === "transparent") {
      setBackground("rgba(0,0,0,.8)")
    } else {
      setBackground("transparent")
    }
  }

  const data = useStaticQuery(graphql`
    query getFile {
      file(base: { eq: "logoBud.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Fragment>
      <Navbar
        className={styles.HeaderContainer}
        bg={"rgba(0,0,0,.3)"}
        id="Navbar"
        collapseOnSelect
        expand="lg"
        variant="dark"
      >
        <Navbar.Brand>
          <Link to="/" id="/home/">
            <Img
              fluid={data.file.childImageSharp.fluid}
              className={styles.HeaderIcon}
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => changeBackground()}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={styles.HeaderNavLinks} /*activeKey={location}*/>
            <Link
              to="/producto/"
              id="/producto/"
              className={styles.HeaderLink}
              onClick={() => {
                window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "menu",
                  event_action: "Click",
                  event_label: "https://www.budweiser.com.ar/producto/",
                  interaction: true,
                  component_name: "",
                  element_text: "",
                })
              }}
            >
              <p id="black1">Nuestra birra</p>
            </Link>
            {/* <Link
              to="/futbol/"
              id="/futbol/"
              className={styles.HeaderLink}
              onClick={() => {
                window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "menu",
                  event_action: "Click",
                  event_label: "https://www.budweiser.com.ar/futbol/",
                  interaction: true,
                  component_name: "",
                  element_text: "",
                })
              }}
            >
              <p id="black2">Fútbol</p>
            </Link> */}
            {/* <Link
              to="/sustentabilidad/"
              id="/sustentabilidad/"
              className={styles.HeaderLink}
              onClick={() => {
                window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "menu",
                  event_action: "Click",
                  event_label: "https://www.budweiser.com.ar/sustentabilidad/",
                  interaction: true,
                  component_name: "",
                  element_text: "",
                })
              }}
            >
              <p id="black3">Sustentabilidad</p>
            </Link> */}
            
            {/* <Link to="/budsummer/" id="/budsummer/" className={styles.HeaderLink}><p id="black1">BudSummer</p></Link>
                        <Link to="/budx/" id="/budx/" className={styles.HeaderLink}><p id="black1">BudX</p></Link> */}
            {/* <Link to="/backtolive/" id="/backtolive/" className={styles.HeaderLink}><p id="black1">BackToLive</p></Link> */}
            <Link
              to="/lamusicaentusmanos/"
              id="/thebirrabehindthemusic/"
              className={styles.HeaderLink}
              onClick={() => {
                window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "menu",
                  event_action: "Click",
                  event_label: "https://www.budweiser.com.ar/lamusicaentusmanos/",
                  interaction: true,
                  component_name: "",
                  element_text: "",
                })
              }}
            >
              <p id="black1">Nuestros Eventos</p>
            </Link>
            <a
              href="https://www.tada.com.ar/catalogo/grupo/budweiser"
              target="__blank"
              className={styles.HeaderLink}
              onClick={() => {
                window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "menu",
                  event_action: "Click",
                  event_label: "Comprar",
                  interaction: true,
                  component_name: "boton_comprar",
                  element_text: "Comprar",
                })
              }}
            >
              <p id="black4">Comprar</p>
            </a>
            <a
              href="https://www.budweiser.com.ar/merchBUD"
              // target="__blank"
              className={styles.HeaderLink}
              onClick={() => {
                window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "menu",
                  event_action: "Click",
                  event_label: "Ganá Merch",
                  interaction: true,
                  component_name: "boton_merch",
                  element_text: "Merch",
                })
              }}
            >
              <p id="black4">Ganá Merch</p>
            </a>
            {/* <Link to="/messi644"  className={styles.HeaderLink}><p>Messi644</p></Link> */}
          </Nav>
          <Nav>
            {/* <Link
              to="#"
              onClick={e => {
                props.handlerModalRegisterOld(e)
                window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "menu",
                    event_action: "Click",
                    event_label: "https://www.budweiser.com.ar/#",
                    interaction: true,
                    component_name: "",
                    element_text: "",
                  })
              }}
              className={styles.HeaderNavLinkLogin}
            >
              <p className={styles.HeaderLogin} id="black5">
                Register
              </p>
            </Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Fragment>
  )
}

export default Header
